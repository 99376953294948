import React from "react"
import { PageLayout } from "../components/Layout"
import TextSectionBackground from "../components/TextSectionBackground"
import { PageHeader } from "../components/Headings 1"
import { SubSectionHeader } from "../components/Headings 2"
import { useStaticQuery, graphql } from "gatsby"
import ImageRow from "../components/ImageRow"
import SEO from "../components/SEO"

const Lodge = () => {
  const {
    contentfulAboutPage: { line },
  } = useStaticQuery(graphql`
    {
      contentfulAboutPage {
        line {
          header
          description {
            json
          }
          imageDesction {
            fluid(maxWidth: 610, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)

  return (
    <PageLayout view={true}>
      <SEO
        title="About Mavela Game Lodge | World-Class Private Safari Experience"
        description="Mavela Game Lodge is nestled in the heart of the magnificent Big 5 Manyoni Private Game Reserve. We pride ourselves on giving our guests an authentic African bush experience."
      />
      <TextSectionBackground pa="4% 10%">
        <PageHeader>
          What Makes Mavela Game Lodge a Perfect Safari Getaway?
        </PageHeader>
        <SubSectionHeader>
          We’re an authentic luxury safari destination that prides itself in
          giving our guests an all-inclusive, intimate African bush experience.
          Being family owned we treat our guests like family, there is no other
          place you’ll feel quite at home and receive the best in five-star
          treatment.
        </SubSectionHeader>
        {line.map((about: any, i: number) => (
          <ImageRow
            id={about.header.split(" ").slice(-1)[0]}
            key={about.header}
            reverse={!!(i % 2)}
            pic={about.imageDesction.fluid}
            header={about.header}
            text={about.description.json}
          ></ImageRow>
        ))}
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Lodge
